import { FunctionalArea } from '@app/shared/business-continuity/functional-area.model';
import { User } from '@app/shared/user.model';
import { RevisionStatusStyle } from '@app/shared/business-continuity/revistion-status-style.model';
import { PortalFunctionalAreaMessageRecipient } from '@app/shared/business-continuity/portal-function-area-message-recipient.model';
import { PortalProcess } from '@app/shared/business-continuity/portal-process.model';

export class PortalFunctionalArea {
	portalFunctionalAreaId: string = null;
	portalId: string = null;
	siteId: string = null;
	portalPlanTypeId: string = null;
	portalFunctionalAreaName: string = null;
	portalFunctionalAreaNameAsBase64: string = '';
	portalFunctionalAreaDescription: string = null;
	portalFunctionalAreaDescriptionAsBase64: string = '';
	functionalArea: FunctionalArea = null;
	portalFunctionalAreaStatusTypeId: string = null;
	leader: User = new User();
	alternateLeaders: Array<User>;
	dateTimeCreated: Date = null;
	createdById: string = null;
	dateTimeLastModified: Date = null;
	lastModifiedById: string = null;
	isCustom: boolean = false;
	portalProcesses: Array<PortalProcess>;
	isHidden: boolean = false;
	documentFolderId: string = null;

	// front end display purpose
	isRenaming: boolean = false;
	alias: string = ''; // used for renaming - store the changes/new name
	style: RevisionStatusStyle;
	doaStyle: RevisionStatusStyle;
	oosStyle: RevisionStatusStyle;
	teamStyle: RevisionStatusStyle;
	locationStyle: RevisionStatusStyle;
	dueDate: Date = null;
	originalDueDate: Date = null;
	messageRecipient: PortalFunctionalAreaMessageRecipient = null;

	associatedDepartmentId: string = null;

	constructor(siteId?: string) {
		this.siteId = siteId;
	}
}

export class PortalFunctionalAreaPlan extends PortalFunctionalArea {
	planActivationId: string = null;
	activationStateTypeId: string = null;

	constructor() {
		super();
	}
}

export class ModelGetPortalFunctionalAreaResponse {
	portalFunctionalArea: PortalFunctionalArea;
}
